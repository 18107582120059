import axios from 'axios' 

const state = {
    token: '',
    user: {},
    message: ''
}

const getters = {
    token(state) {
        return state.token
    }, 
    
    user(state){
        return state.user
    }
}

const actions = {
    async login({dispatch}, user) {
        let response = await axios.post('auth/login', {
            email: user.email, 
            password: user.password 
        })

        dispatch('me', response.data.access_token)
    },

    async me({commit, state}, token) {
        if(token) {
            commit('SET_TOKEN', token)
        }

        if(!state.token) {
            return
        }

        try {
            const response = await axios.post('auth/me')
            commit('SET_USER', response.data)
        } catch (e) {
            commit('SET_USER', null)
            commit('SET_TOKEN', null)
        }
    }
}

const mutations = {
    SET_TOKEN(state, data){
        state.token = data
    },
    
    SET_USER(state, data) {
        state.user = data
    },

    SET_MESSAGE(state, data){
        state.message = data
    }
}

export default {
    namespaced: true, 
    actions,
    state, 
    mutations, 
    getters
}