import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/Login.vue'
import store from '../store'


Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: 'login',
  },
  {
    path: '/',
    redirect: 'login',
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue'), 
    beforeEnter: (to, from, next) => {
        // console.log(store.getters['login/token']);
        if(!store.getters['login/token']){
          router.push("/login")
        }
        next()
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
