import axios from 'axios';
import store from '../index'

store.subscribe((mutation) => {
    console.log(mutation.payload);
    switch (mutation.type) {
        case 'login/SET_TOKEN':
            if(mutation.payload) { 
                axios.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`
                localStorage.setItem('spa_token', mutation.payload)
            } else {
                axios.defaults.headers.common['Authorization'] = null
                localStorage.removeItem('spa_token')
            }
            break;
    }
    
})